import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Typography, Slider, Input, Button, Col, Skeleton, Form, Select, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import { SketchPicker } from "react-color";

import { UploadOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

const SecondSessionContent = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);

  const [firstColor, setFirstColor] = useState("#8fb1dd");
  const [secondColor, setSecondColor] = useState("#fff");
  const [gradientDirection, setGradientDirection] = useState(45);

  const [secondSectionTitle, setSecondSectionTitle] = useState("");
  const [secondSectionText, setSecondSectionText] = useState("");
  const [secondSectionYoutubeLink, setSecondSectionYoutubeLink] = useState("");
  const [secondSectionHasImageOrVideo, setSecondSectionHasImageOrVideo] = useState("video");
  const [secondSectionImageSrc, setSecondSectionImageSrc] = useState(null);
  const [secondSectionButtonHref, setSecondSectionButtonHref] = useState("");

  const [secondSectionButtonTxt, setSecondSectionButtonTxt] = useState("");


  const options = [
    { value: 'image', label: 'Imagem' },
    { value: 'video', label: 'Vídeo' }
  ];

  const handleSliderChange = (value) => {
    setGradientDirection(value);
  };

  const handleSave = () => {
    const jsonData = {
      SecondSectionTitle: secondSectionTitle,
      SecondSectionText: secondSectionText,
      SecondSectionYoutubeLink: secondSectionYoutubeLink,
      SecondSectionHasImageOrVideo: secondSectionHasImageOrVideo,
      SecondSectionImageSrc: secondSectionImageSrc,
      SecondSectionButtonHref: secondSectionButtonHref,
      SecondSectionButtonTxt: secondSectionButtonTxt
    };
    console.log(JSON.stringify(jsonData));
    let body = JSON.stringify(jsonData);
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/segundaSessaoTexto/`+idLoja, body,
        options
      ).then((resp) => {
        if (resp.status == 200) {
          message.success('Conteudo salvo!');
        }
        setLoading(false);
      });

    } catch (error) {
      console.error('Erro ao chamar a API:', error.status);
      if (error.status === 404) {
        message.error('Erro ao gravar!');
      }
      setLoading(false);
    }
    
  };



  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const compressedFile = await handleImageCompression(acceptedFiles[0]);
      const base64 = await convertToBase64(compressedFile);
      localStorage.setItem("secondSectionImageSrc", base64);
      setSecondSectionImageSrc(base64); // Armazena a string base64 diretamente
      message.success('Imagem carregada e convertida com sucesso!');
    },
  });

  const handleImageCompression = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      fileType: 'image/webp',
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
      message.error('Erro ao comprimir a imagem!');
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

  const loadDados = () => {

    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance.get(
      `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/`+idLoja,
      options
    ).then((resp) => {
      const data = resp.data.SecondSectionContents;
      setSecondSectionTitle(data.SecondSectionTitle);
      setSecondSectionText(data.SecondSectionText);
      setSecondSectionYoutubeLink(data.SecondSectionYoutubeLink);
      setSecondSectionHasImageOrVideo(data.SecondSectionHasImageOrVideo);
      setSecondSectionImageSrc(data.SecondSectionImageSrc);
      setSecondSectionButtonHref(data.SecondSectionButtonHref);
      setSecondSectionButtonTxt(data.SecondSectionButtonTxt)

      console.log(data);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error.response.status === 404) {
        console.error("Data not found");
      }
    });
  }

  useEffect(() => {
    setLoading(true);

    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, reload, idLoja]);


  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Conteúdo Segunda Seção
        </h1>

        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="SecondSectionForm"
                  onFinish={handleSave}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                                        label={
                                          <>
                                            Título da Segunda Seção &nbsp;<b>(Para não exibir a seção basta apagar o texto e salvar sem título)</b>
                                          </>
                                        }
                    name="SecondSectionTitle"
                    initialValue={secondSectionTitle}
                    rules={[
                      { required: false, message: "Por favor, insira o título!" },
                    ]}
                  >
                    <Input
                      value={secondSectionTitle}
                      onChange={(e) => setSecondSectionTitle(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Texto da Segunda Seção"
                    name="SecondSectionText"
                    initialValue={secondSectionText}
                    rules={[
                      { required: false, message: "Por favor, insira o texto!" },
                    ]}
                  >
                    <Input.TextArea
                      value={secondSectionText}
                      onChange={(e) => setSecondSectionText(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link do Youtube"
                    name="SecondSectionYoutubeLink"
                    initialValue={secondSectionYoutubeLink}
                  >
                    <Input
                      value={secondSectionYoutubeLink}
                      onChange={(e) => setSecondSectionYoutubeLink(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Imagem ou Vídeo"
                    name="SecondSectionHasImageOrVideo"
                    initialValue={secondSectionHasImageOrVideo}
                  >
                    <Select
                      value={secondSectionHasImageOrVideo}
                      onChange={(value) => setSecondSectionHasImageOrVideo(value)}
                      options={options}
                    />
                  </Form.Item>

                  
                  <Form.Item
                    label="Fonte da Imagem (Recomendado: 600x600)"
                    name="SecondSectionImageSrc"
                  >
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <Button icon={<UploadOutlined />}>Carregar Imagem</Button>
                    </div>
                    {secondSectionImageSrc && (
                      <img src={secondSectionImageSrc} alt="Preview" style={{ width: '30%', marginTop: '10px' }} />
                    )}
                  </Form.Item>
                  

                  <Form.Item
                    label="Texto do Botão (deixe vazio se não desejar o botão)"
                    name="SecondSectionButtonTxt"
                    initialValue={secondSectionButtonTxt}
                  >
                    <Input
                      value={secondSectionButtonTxt}
                      onChange={(e) => setSecondSectionButtonTxt(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link do Botão"
                    name="SecondSectionButtonHref"
                    initialValue={secondSectionButtonHref}
                  >
                    <Input
                      value={secondSectionButtonHref}
                      onChange={(e) => setSecondSectionButtonHref(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SecondSessionContent;
