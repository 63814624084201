import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Skeleton, Form, Input, Button, Col, Switch, Select, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import camuflado from "../../../fundo.jpg";

const SocialInfosForm = () => {
  const accessToken = window.localStorage.getItem("accessToken");


  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);


  const [loading, setLoading] = useState(false);
  const [socialInfos, setSocialInfos] = useState({
    Footercolor: "preto",
    FooterWhatsappActive: true,
    FooterWhatsappNumber: "11963697271",
    FooterInstagramActive: true,
    FooterInstagramUser: "techunionti",
    FooterFacebookActive: true,
    FooterFacebookUser: "techunionti",
    FooterTechsappActive: true,
    FooterTechsappLink: "https://techgourmet.com.br/techsapppadrao",
    FooterTelefoneActive: false,
    FooterTelefoneNumber: "11963697271",
    FooterTwitterActive: false,
    FooterTwitterUser: "TechUnion_TI",
    FooterYoutubeActive: true,
    FooterYoutubeUser: "TechUnionSolucoesEmTI",
  });

  const handleSave = () => {
    console.log(JSON.stringify(socialInfos));
    let body = JSON.stringify(socialInfos);

    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/iconesFooter/`+idLoja,
        body,
        options
      ).then((resp) => {
        if (resp.status === 200) {
          message.success("Conteúdo salvo!");
        }
        setLoading(false);
      });

    } catch (error) {
      console.error("Erro ao chamar a API:", error.status);
      if (error.status === 404) {
        message.error("Erro ao gravar!");
      }
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setSocialInfos((prev) => ({ ...prev, [field]: value }));
  };



  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

// Função para carregar os dados
const loadDados = () => {
  setLoading(true);
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };
  axiosInstance.get(
    `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/`+idLoja,
    options
  ).then((resp) => {
    const sectionColors = resp.data.LinksFooter;
    
    setSocialInfos({
      Footercolor: sectionColors.Footercolor,
      FooterWhatsappActive: sectionColors.FooterWhatsappActive,
      FooterWhatsappNumber: sectionColors.FooterWhatsappNumber,
      FooterInstagramActive: sectionColors.FooterInstagramActive,
      FooterInstagramUser: sectionColors.FooterInstagramUser,
      FooterFacebookActive: sectionColors.FooterFacebookActive,
      FooterFacebookUser: sectionColors.FooterFacebookUser,
      FooterTechsappActive: sectionColors.FooterTechsappActive,
      FooterTechsappLink: sectionColors.FooterTechsappLink,
      FooterTelefoneActive: sectionColors.FooterTelefoneActive,
      FooterTelefoneNumber: sectionColors.FooterTelefoneNumber,
      FooterTwitterActive: sectionColors.FooterTwitterActive,
      FooterTwitterUser: sectionColors.FooterTwitterUser,
      FooterYoutubeActive: sectionColors.FooterYoutubeActive,
      FooterYoutubeUser: sectionColors.FooterYoutubeUser
    });

    console.log(sectionColors);

    setLoading(false);
  }).catch((error) => {
    console.error('Erro ao carregar dados:', error);
    setLoading(false);
  });
}

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  
  }, [idUser, accessToken, reload, idLoja]);

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Redes Sociais Rodapé
        </h1>

        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="SocialInfosForm"
                  onFinish={handleSave}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="Cor Principal"
                    name="color"
                    initialValue={socialInfos.Footercolor}
                  >
                    <Select
                      value={socialInfos.Footercolor}
                      onChange={(value) => handleInputChange("Footercolor", value)}
                    >
                      <Select.Option value="azul">Azul</Select.Option>
                      <Select.Option value="preto">Preto</Select.Option>
                      <Select.Option value="branco">Branco</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Exibir WhatsApp"
                    name="WhatsappActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterWhatsappActive}
                  >
                    <Switch
                      checked={socialInfos.FooterWhatsappActive}
                      onChange={(checked) => handleInputChange("FooterWhatsappActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Número WhatsApp"
                    name="WhatsappNumber"
                    initialValue={socialInfos.FooterWhatsappNumber}
                  >
                    <Input
                      value={socialInfos.FooterWhatsappNumber}
                      onChange={(e) => handleInputChange("FooterWhatsappNumber", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Instagram"
                    name="InstagramActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterInstagramActive}
                  >
                    <Switch
                      checked={socialInfos.FooterInstagramActive}
                      onChange={(checked) => handleInputChange("FooterInstagramActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Instagram"
                    name="InstagramUser"
                    initialValue={socialInfos.FooterInstagramUser}
                  >
                    <Input
                      value={socialInfos.FooterInstagramUser}
                      onChange={(e) => handleInputChange("FooterInstagramUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Facebook"
                    name="FacebookActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterFacebookActive}
                  >
                    <Switch
                      checked={socialInfos.FooterFacebookActive}
                      onChange={(checked) => handleInputChange("FooterFacebookActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Facebook"
                    name="FacebookUser"
                    initialValue={socialInfos.FooterFacebookUser}
                  >
                    <Input
                      value={socialInfos.FooterFacebookUser}
                      onChange={(e) => handleInputChange("FooterFacebookUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Techsapp"
                    name="TechsappActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterTechsappActive}
                  >
                    <Switch
                      checked={socialInfos.FooterTechsappActive}
                      onChange={(checked) => handleInputChange("FooterTechsappActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link Techsapp"
                    name="TechsappLink"
                    initialValue={socialInfos.FooterTechsappLink}
                  >
                    <Input
                      value={socialInfos.FooterTechsappLink}
                      onChange={(e) => handleInputChange("FooterTechsappLink", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Telefone"
                    name="TelefoneActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterTelefoneActive}
                  >
                    <Switch
                      checked={socialInfos.FooterTelefoneActive}
                      onChange={(checked) => handleInputChange("FooterTelefoneActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Número de Telefone"
                    name="TelefoneNumber"
                    initialValue={socialInfos.FooterTelefoneNumber}
                  >
                    <Input
                      value={socialInfos.FooterTelefoneNumber}
                      onChange={(e) => handleInputChange("FooterTelefoneNumber", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Twitter"
                    name="TwitterActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterTwitterActive}
                  >
                    <Switch
                      checked={socialInfos.FooterTwitterActive}
                      onChange={(checked) => handleInputChange("FooterTwitterActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Twitter"
                    name="TwitterUser"
                    initialValue={socialInfos.FooterTwitterUser}
                  >
                    <Input
                      value={socialInfos.FooterTwitterUser}
                      onChange={(e) => handleInputChange("FooterTwitterUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Youtube"
                    name="YoutubeActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FooterYoutubeActive}
                  >
                    <Switch
                      checked={socialInfos.FooterYoutubeActive}
                      onChange={(checked) => handleInputChange("FooterYoutubeActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Youtube"
                    name="YoutubeUser"
                    initialValue={socialInfos.FooterYoutubeUser}
                  >
                    <Input
                      value={socialInfos.FooterYoutubeUser}
                      onChange={(e) => handleInputChange("FooterYoutubeUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SocialInfosForm;
