import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Skeleton, Form, Input, Button, Col, Switch, Select, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import camuflado from "../../../fundo.jpg";

const SocialInfosForm = () => {
  const accessToken = window.localStorage.getItem("accessToken");


  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);


  const [loading, setLoading] = useState(false);
  const [socialInfos, setSocialInfos] = useState({
    color: "preto",
    WhatsappActive: true,
    WhatsappNumber: "11963697271",
    InstagramActive: true,
    InstagramUser: "techunionti",
    FacebookActive: true,
    FacebookUser: "techunionti",
    TechsappActive: true,
    TechsappLink: "https://techgourmet.com.br/techsapppadrao",
    TelefoneActive: false,
    TelefoneNumber: "11963697271",
    TwitterActive: false,
    TwitterUser: "TechUnion_TI",
    YoutubeActive: true,
    YoutubeUser: "TechUnionSolucoesEmTI",
  });

  const handleSave = () => {
    console.log(JSON.stringify(socialInfos));
    let body = JSON.stringify(socialInfos);

    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/iconesCima/`+idLoja,
        body,
        options
      ).then((resp) => {
        if (resp.status === 200) {
          message.success("Conteúdo salvo!");
        }
        setLoading(false);
      });

    } catch (error) {
      console.error("Erro ao chamar a API:", error.status);
      if (error.status === 404) {
        message.error("Erro ao gravar!");
      }
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setSocialInfos((prev) => ({ ...prev, [field]: value }));
  };



  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

// Função para carregar os dados
const loadDados = () => {
  setLoading(true);
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };
  axiosInstance.get(
    `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/`+idLoja,
    options
  ).then((resp) => {
    const sectionColors = resp.data.SocialInfos;
    
    setSocialInfos({
      color: sectionColors.color,
      WhatsappActive: sectionColors.WhatsappActive,
      WhatsappNumber: sectionColors.WhatsappNumber,
      InstagramActive: sectionColors.InstagramActive,
      InstagramUser: sectionColors.InstagramUser,
      FacebookActive: sectionColors.FacebookActive,
      FacebookUser: sectionColors.FacebookUser,
      TechsappActive: sectionColors.TechsappActive,
      TechsappLink: sectionColors.TechsappLink,
      TelefoneActive: sectionColors.TelefoneActive,
      TelefoneNumber: sectionColors.TelefoneNumber,
      TwitterActive: sectionColors.TwitterActive,
      TwitterUser: sectionColors.TwitterUser,
      YoutubeActive: sectionColors.YoutubeActive,
      YoutubeUser: sectionColors.YoutubeUser
    });

    console.log(sectionColors);

    setLoading(false);
  }).catch((error) => {
    console.error('Erro ao carregar dados:', error);
    setLoading(false);
  });
}

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  
  }, [idUser, accessToken, reload, idLoja]);

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Redes Sociais Primeira Seção
        </h1>

        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="SocialInfosForm"
                  onFinish={handleSave}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="Cor Principal"
                    name="color"
                    initialValue={socialInfos.color}
                  >
                    <Select
                      value={socialInfos.color}
                      onChange={(value) => handleInputChange("color", value)}
                    >
                      <Select.Option value="azul">Azul</Select.Option>
                      <Select.Option value="preto">Preto</Select.Option>
                      <Select.Option value="branco">Branco</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Exibir WhatsApp"
                    name="WhatsappActive"
                    valuePropName="checked"
                    initialValue={socialInfos.WhatsappActive}
                  >
                    <Switch
                      checked={socialInfos.WhatsappActive}
                      onChange={(checked) => handleInputChange("WhatsappActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Número WhatsApp"
                    name="WhatsappNumber"
                    initialValue={socialInfos.WhatsappNumber}
                  >
                    <Input
                      value={socialInfos.WhatsappNumber}
                      onChange={(e) => handleInputChange("WhatsappNumber", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Instagram"
                    name="InstagramActive"
                    valuePropName="checked"
                    initialValue={socialInfos.InstagramActive}
                  >
                    <Switch
                      checked={socialInfos.InstagramActive}
                      onChange={(checked) => handleInputChange("InstagramActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Instagram"
                    name="InstagramUser"
                    initialValue={socialInfos.InstagramUser}
                  >
                    <Input
                      value={socialInfos.InstagramUser}
                      onChange={(e) => handleInputChange("InstagramUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Facebook"
                    name="FacebookActive"
                    valuePropName="checked"
                    initialValue={socialInfos.FacebookActive}
                  >
                    <Switch
                      checked={socialInfos.FacebookActive}
                      onChange={(checked) => handleInputChange("FacebookActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Facebook"
                    name="FacebookUser"
                    initialValue={socialInfos.FacebookUser}
                  >
                    <Input
                      value={socialInfos.FacebookUser}
                      onChange={(e) => handleInputChange("FacebookUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Techsapp"
                    name="TechsappActive"
                    valuePropName="checked"
                    initialValue={socialInfos.TechsappActive}
                  >
                    <Switch
                      checked={socialInfos.TechsappActive}
                      onChange={(checked) => handleInputChange("TechsappActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link Techsapp"
                    name="TechsappLink"
                    initialValue={socialInfos.TechsappLink}
                  >
                    <Input
                      value={socialInfos.TechsappLink}
                      onChange={(e) => handleInputChange("TechsappLink", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Telefone"
                    name="TelefoneActive"
                    valuePropName="checked"
                    initialValue={socialInfos.TelefoneActive}
                  >
                    <Switch
                      checked={socialInfos.TelefoneActive}
                      onChange={(checked) => handleInputChange("TelefoneActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Número de Telefone"
                    name="TelefoneNumber"
                    initialValue={socialInfos.TelefoneNumber}
                  >
                    <Input
                      value={socialInfos.TelefoneNumber}
                      onChange={(e) => handleInputChange("TelefoneNumber", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Twitter"
                    name="TwitterActive"
                    valuePropName="checked"
                    initialValue={socialInfos.TwitterActive}
                  >
                    <Switch
                      checked={socialInfos.TwitterActive}
                      onChange={(checked) => handleInputChange("TwitterActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Twitter"
                    name="TwitterUser"
                    initialValue={socialInfos.TwitterUser}
                  >
                    <Input
                      value={socialInfos.TwitterUser}
                      onChange={(e) => handleInputChange("TwitterUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Exibir Youtube"
                    name="YoutubeActive"
                    valuePropName="checked"
                    initialValue={socialInfos.YoutubeActive}
                  >
                    <Switch
                      checked={socialInfos.YoutubeActive}
                      onChange={(checked) => handleInputChange("YoutubeActive", checked)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Usuário Youtube"
                    name="YoutubeUser"
                    initialValue={socialInfos.YoutubeUser}
                  >
                    <Input
                      value={socialInfos.YoutubeUser}
                      onChange={(e) => handleInputChange("YoutubeUser", e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SocialInfosForm;
