import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Col, Skeleton, Button, message, Input } from "antd";
import { SketchPicker } from "react-color";
import axiosInstance from "../../../axiosInterceptor";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";

const HeaderConfig = () => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);

  const [headerLinkColor, setHeaderLinkColor] = useState("#fff");
  const [headerBgColorOnScroll, setHeaderBgColorOnScroll] = useState("#0d8bd9");
  const [headerLinksColorOnScroll, setHeaderLinksColorOnScroll] = useState("#fff");
  const [dividerColor, setDividerColor] = useState("#000"); // Cor inicial do divisor


  const [Font1, setFont1] = useState(null);
  const [Font2, setFont2] = useState(null);
  const [Font3, setFont3] = useState(null);

  const [logo, setLogo] = useState(null); // Campo para logo (base64)
  const [favicon, setFavicon] = useState(null); // Campo para favicon (base64)
  const [titulo, setTitulo] = useState(""); // Campo para título

  // Função para carregar dados da loja
  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then((resp) => {
          setIDLoja(resp.data.id);
          setLoja(resp.data);
        });
    }
  }, [idUser]);

  // Função para salvar as configurações
  
  const handleSave = () => {

    console.log("Logo:", logo);
console.log("Favicon:", favicon);

    const jsonData = {
      Font1: Font1,
      Font2: Font2,
      Font3: Font3,
      HeaderLinkColor: headerLinkColor,
      HeaderbgColorOnScroll: headerBgColorOnScroll,
      HeaderLinksColorOnScroll: headerLinksColorOnScroll,
      logo: logo, // Adiciona logo
      favicon: favicon, // Adiciona favicon
      titulo: titulo, // Adiciona título
      divider: dividerColor,
    };

    let body = JSON.stringify(jsonData);
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance
        .put(`https://api.pedtech.com.br/v1/pedtech/headerSessaoCor/` + idLoja, body, options)
        .then((resp) => {
          if (resp.status === 200) {
            message.success("Configurações do cabeçalho salvas!");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao chamar a API:", error.status);
          message.error("Erro ao gravar as configurações do cabeçalho!");
          setLoading(false);
        });
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      message.error("Erro ao gravar as configurações do cabeçalho!");
      setLoading(false);
    }
  };

  // Função para carregar dados do cabeçalho
  const loadDados = () => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance
      .get(`https://api.pedtech.com.br/v1/pedtech/getByEmpresa/` + idLoja, options)
      .then((resp) => {
        const headerConfig = resp.data.HeaderColors;
        setHeaderLinkColor(headerConfig.HeaderLinkColor);
        setHeaderBgColorOnScroll(headerConfig.HeaderbgColorOnScroll);
        setHeaderLinksColorOnScroll(headerConfig.HeaderLinksColorOnScroll);
        setFont1(headerConfig.Font1);
        setFont2(headerConfig.Font2);
        setFont3(headerConfig.Font3);
        setLogo(headerConfig.logo);
        setFavicon(headerConfig.favicon);
        setTitulo(headerConfig.titulo);
        setDividerColor(headerConfig.divider);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, idLoja]);

// Função para processar o upload e compressão de imagem do logo
const { getRootProps: getLogoProps, getInputProps: getLogoInputProps } = useDropzone({
  accept: "image/*",
  onDrop: async (acceptedFiles) => {
    const compressedFile = await handleImageCompression(acceptedFiles[0]);
    const base64 = await convertToBase64(compressedFile);
    setLogo(base64); // Armazena logo como base64
    message.success("Imagem da logo carregada com sucesso!");
  },
});

// Função para processar o upload e compressão de imagem do favicon
const { getRootProps: getFaviconProps, getInputProps: getFaviconInputProps } = useDropzone({
  accept: "image/*",
  onDrop: async (acceptedFiles) => {
    const compressedFile = await handleImageCompression(acceptedFiles[0]);
    const base64 = await convertToBase64(compressedFile);
    setFavicon(base64); // Armazena favicon como base64
    message.success("Imagem do favicon carregada com sucesso!");
  },
});


  const handleImageCompression = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Erro ao comprimir a imagem:", error);
      message.error("Erro ao comprimir a imagem!");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1 style={{ textAlign: "center" }}>Configuração de Cores do Cabeçalho</h1>

        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Cor dos Links do Cabeçalho:</h3>
                <Row align="middle">
                  <Col span={16}>
                    <SketchPicker
                      color={headerLinkColor}
                      onChangeComplete={(color) => setHeaderLinkColor(color.hex)}
                      disableAlpha={true}
                    />
                  </Col>
                  <Col span={8}>
                    <Preview style={{ backgroundColor: headerBgColorOnScroll, color: headerLinkColor }}>
                      Link Preview
                    </Preview>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12} lg={7}>
                <h3>Cor do Fundo do Cabeçalho ao Rolar:</h3>
                <Row align="middle">
                  <Col span={16}>
                    <SketchPicker
                      color={headerBgColorOnScroll}
                      onChangeComplete={(color) => setHeaderBgColorOnScroll(color.hex)}
                      disableAlpha={true}
                    />
                  </Col>
                  <Col span={8}>
                    <Preview style={{ backgroundColor: headerBgColorOnScroll, color: headerLinkColor }}>
                      Fundo Preview
                    </Preview>
                  </Col>
                </Row>
              </Col>


                      <Col xs={24} md={12} lg={7}>
          <h3>Cor do Divisor de Páginas:</h3>
          <Row align="middle">
            <Col span={16}>
              <SketchPicker
                color={dividerColor}
                onChangeComplete={(color) => setDividerColor(color.hex)}
                disableAlpha={true}
              />
            </Col>
            <Col span={8}>
              <Preview style={{ backgroundColor: dividerColor, color: '#fff' }}>
                Divisor Preview
              </Preview>
            </Col>
          </Row>
        </Col>



            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Logo do Cabeçalho: (Tamanho Obrigatório: até 600x600)</h3>
                <div {...getLogoProps()} style={{ border: "1px dashed #ddd", padding: "20px", textAlign: "center" }}>
                  <input id="logo" {...getLogoInputProps()} />
                  {logo ? (
                    <img src={logo} id="logo1" alt="Logo" style={{ maxWidth: "100%" }} />
                  ) : (
                    <p>Clique ou arraste uma imagem para carregar a logo</p>
                  )}
                </div>
              </Col>

              <Col xs={24} md={12} lg={7}>
                <h3>Favicon do Cabeçalho: (Tamanho Obrigatório: 32x32)</h3>
                <div {...getFaviconProps()} style={{ border: "1px dashed #ddd", padding: "20px", textAlign: "center" }}>
                  <input id="favicon" {...getFaviconInputProps()} />
                  {favicon ? (
                    <img src={favicon} id="favicon1" alt="Favicon" style={{ maxWidth: "100px", maxHeight: "100px" }} />
                  ) : (
                    <p>Clique ou arraste uma imagem para carregar o favicon</p>
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Título da Loja:</h3>
                <Input value={titulo} onChange={(e) => setTitulo(e.target.value)} placeholder="Título da Loja" />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <Button
                  onClick={handleSave}
                  type="primary"
                  loading={loading}
                  block
                  style={{ backgroundColor: "#5cb85c", borderColor: "#5cb85c" }}
                >
                  Salvar Configurações
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Container>
  );
};

export default HeaderConfig;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Preview = styled.div`
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
`;
