import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Typography, Slider, Button, Col, Skeleton, message  } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import { SketchPicker } from "react-color";

const Home = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);

  const [firstColor, setFirstColor] = useState("#8fb1dd");
  const [secondColor, setSecondColor] = useState("#000");
  const [gradientDirection, setGradientDirection] = useState(45);

  const [firstSectionTitleColor, setFirstSectionTitleColor] = useState("#000");
  const [firstSectionTextColor, setFirstSectionTextColor] = useState("#000");

  const [firstSectionButtonColor, setFirstSectionButtonColor] = useState("#000");
  const [firstSectionButtonTextColor, setFirstSectionButtonTextColor] = useState("#000");

  const handleSliderChange = (value) => {
    setGradientDirection(value);
  };

  const parseGradient = (gradientString) => {
    const regex = /linear-gradient\((\d+)deg,\s*(#[a-fA-F0-9]{6}),\s*(#[a-fA-F0-9]{6})\)/;
    const matches = gradientString.match(regex);
    if (matches) {
      return {
        direction: parseInt(matches[1], 10),
        color1: matches[2],
        color2: matches[3],
      };
    }
    return null;
  };

  const handleSave = () => {
    const jsonData = {
      FirstSectionTitleColor: firstSectionTitleColor,
      FirstSectionTextColor: firstSectionTextColor,
      FirstSectionBackground: `linear-gradient(${gradientDirection}deg, ${firstColor}, ${secondColor})`,
      FirstSectionButtonColor: firstSectionButtonColor,
      FirstSectionButtonTextColor: firstSectionButtonTextColor,
    };
    let body = JSON.stringify(jsonData);
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/primeiraSessaoCor/`+idLoja, body,
        options
      ).then((resp) => {
        if (resp.status == 200) {
          message.success('Cores salva!');
        }

        setLoading(false);

      });

    } catch (error) {
      console.error('Erro ao chamar a API:', error.status);
      if (error.status === 404) {
        message.error('Erro ao gravar as Cores!');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        console.log(resp.data);
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

  const loadDados = () => {
    console.log(idLoja);
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
  
    axiosInstance.get(
      `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/` + idLoja,
      options
    )
    .then((resp) => {
      const sectionColors = resp.data.FirstSectionColors;
      setFirstSectionTitleColor(sectionColors.FirstSectionTitleColor);
      setFirstSectionTextColor(sectionColors.FirstSectionTextColor);
      setFirstSectionButtonColor(sectionColors.FirstSectionButtonColor);
      setFirstSectionButtonTextColor(sectionColors.FirstSectionButtonTextColor);
  
      console.log(sectionColors);
  
      const parsedGradient = parseGradient(sectionColors.FirstSectionBackground);
      if (parsedGradient) {
        setGradientDirection(parsedGradient.direction);
        setFirstColor(parsedGradient.color1);
        setSecondColor(parsedGradient.color2);
      }
  
      setLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status === 404 || error.response.status === 401 || error.response.status === 400) {
        // Se o erro for 404, chama o novo endpoint
        const postData = {
          empresa: idLoja,
          dominio: loja.dominioPedtech,
        };
  
        axiosInstance.post(
          'https://api.pedtech.com.br/v1/pedtech/carga-dados',
          postData,
          options
        )
        .then(() => {
          setLoading(false);
          window.location.reload(); // Recarrega a página após o sucesso
        })
        .catch((postError) => {
          console.error('Erro ao chamar o endpoint de fallback:', postError);
          setLoading(false);
        });
      } else {
        console.error('Erro ao carregar dados:', error);
        setLoading(false);
      }
    });
  }
  

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  
  }, [idUser, accessToken, reload, idLoja]);

  return (


    <Container>



      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Cores Primeira Seção
        </h1>


        {loading ? (
      <Skeleton active />
    ) : (

        <><Row gutter={[16, 16]} style={{
              marginTop: "20px", display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Primeira Cor Fundo:</h3>
                <SketchPicker
                  color={firstColor}
                  onChangeComplete={(color) => setFirstColor(color.hex)}
                  disableAlpha={true} />
              </Col>
              <Col xs={24} md={12} lg={7}>
                <h3>Segunda Cor Fundo:</h3>
                <SketchPicker
                  color={secondColor}
                  onChangeComplete={(color) => setSecondColor(color.hex)}
                  disableAlpha={true} />
              </Col>
              <Col xs={24} md={24} lg={7}>
                <h3>Direção do Gradiente Fundo: {gradientDirection}°</h3>
                <Slider
                  min={0}
                  max={360}
                  value={gradientDirection}
                  onChange={handleSliderChange} />
                <div
                  style={{
                    background: `linear-gradient(${gradientDirection}deg, ${firstColor}, ${secondColor})`,
                    //width: "100px",
                    height: "100px",
                    marginTop: "20px",
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <p>Fundo Preview</p>
                </div>
              </Col>
            </Row><Row gutter={[16, 16]} style={{
              marginTop: "20px", display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
                <Col xs={24} md={12} lg={7}>
                  <h3>Cor do Título:</h3>
                  <SketchPicker
                    color={firstSectionTitleColor}
                    onChangeComplete={(color) => setFirstSectionTitleColor(color.hex)}
                    disableAlpha={true}
                    presetColors={[]} />
                </Col>
                <Col xs={24} md={12} lg={7}>
                  <h3>Cor do Texto:</h3>
                  <SketchPicker
                    color={firstSectionTextColor}
                    onChangeComplete={(color) => setFirstSectionTextColor(color.hex)}
                    disableAlpha={true}
                    presetColors={[]} />
                </Col>


                <Col xs={24} md={12} lg={7} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      marginTop: "20px",
                      borderRadius: "8px",
                      textAlign: "center",
                      background: `linear-gradient(${gradientDirection}deg, ${firstColor}, ${secondColor})`,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center", // Centraliza o conteúdo verticalmente dentro do div
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        color: `${firstSectionTitleColor}`,
                        marginBottom: "10px", // Espaçamento entre título e texto
                      }}
                    >
                      Fonte Titulo
                    </div>

                    <div
                      style={{
                        fontWeight: "bold",
                        color: `${firstSectionTextColor}`,
                      }}
                    >
                      <p>Fonte Texto</p>
                    </div>
                  </div>
                </Col>
              </Row>
              
              <Row gutter={[16, 16]} style={{
  marginTop: "20px", display: "flex",
  alignItems: "center",
  justifyContent: "center",
}}>
  <Col xs={24} md={12} lg={7}>
    <h3>Cor do Botão:</h3>
    <SketchPicker
      color={firstSectionButtonColor}
      onChangeComplete={(color) => setFirstSectionButtonColor(color.hex)}
      disableAlpha={true}
      presetColors={[]} />
  </Col>
  <Col xs={24} md={12} lg={7}>
    <h3>Cor do Texto do Botão:</h3>
    <SketchPicker
      color={firstSectionButtonTextColor}
      onChangeComplete={(color) => setFirstSectionButtonTextColor(color.hex)}
      disableAlpha={true}
      presetColors={[]} />
  </Col>

  <Col xs={24} md={12} lg={7} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
    
  <div
                    style={{
                      width: "200px",
                      height: "200px",
                      marginTop: "20px",
                      borderRadius: "8px",
                      textAlign: "center",
                      background: `linear-gradient(${gradientDirection}deg, ${firstColor}, ${secondColor})`,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center", // Centraliza o conteúdo verticalmente dentro do div
                    }}
                  >

    <div
      style={{
        width: "180px",
        height: "50px",
        marginTop: "20px",
        borderRadius: "8px",
        textAlign: "center",
        background: firstSectionButtonColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center", // Centraliza o conteúdo verticalmente dentro do div
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          color: `${firstSectionButtonTextColor}`,
        }}
      >
        Botão Preview
      </div>
      </div>
    </div>
  </Col>
</Row>

              
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type="primary" onClick={handleSave}>
                  Salvar
                </Button>
              </div></>
            )},
      </Card>

    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default Home;