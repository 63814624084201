import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Typography, Slider, Input, Button, Col, Skeleton, Form, Select, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";
import { SketchPicker } from "react-color";

import { UploadOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

const SecondSessionContent = ({ recarregarDados }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);

  const [firstColor, setFirstColor] = useState("#8fb1dd");
  const [secondColor, setSecondColor] = useState("#fff");
  const [gradientDirection, setGradientDirection] = useState(45);

  const [thirdSectionTitle, setThirdSectionTitle] = useState("");
  const [thirdSectionText, setThirdSectionText] = useState("");
  const [thirdSectionYoutubeLink, setThirdSectionYoutubeLink] = useState("");
  const [thirdSectionHasImageOrVideo, setThirdSectionHasImageOrVideo] = useState("video");
  const [thirdSectionImageSrc, setThirdSectionImageSrc] = useState(null);
  const [thirdSectionButtonHref, setThirdSectionButtonHref] = useState("");

  const [thirdSectionButtonTxt, setThirdSectionButtonTxt] = useState("");


  const options = [
    { value: 'image', label: 'Imagem' },
    { value: 'video', label: 'Vídeo' }
  ];

  const handleSliderChange = (value) => {
    setGradientDirection(value);
  };

  const handleSave = () => {
    const jsonData = {
      ThirdSectionTitle: thirdSectionTitle,
      ThirdSectionText: thirdSectionText,
      ThirdSectionYoutubeLink: thirdSectionYoutubeLink,
      ThirdSectionHasImageOrVideo: thirdSectionHasImageOrVideo,
      ThirdSectionImageSrc: thirdSectionImageSrc,
      ThirdSectionButtonHref: thirdSectionButtonHref,
      ThirdSectionButtonTxt: thirdSectionButtonTxt
    };
    console.log(JSON.stringify(jsonData));
    let body = JSON.stringify(jsonData);
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/terceiraSessaoTexto/`+idLoja, body,
        options
      ).then((resp) => {
        if (resp.status == 200) {
          message.success('Conteudo salvo!');
        }
        setLoading(false);
      });

    } catch (error) {
      console.error('Erro ao chamar a API:', error.status);
      if (error.status === 404) {
        message.error('Erro ao gravar!');
      }
      setLoading(false);
    }
    
  };



  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const compressedFile = await handleImageCompression(acceptedFiles[0]);
      const base64 = await convertToBase64(compressedFile);
      localStorage.setItem("thirdSectionImageSrc", base64);
      setThirdSectionImageSrc(base64); // Armazena a string base64 diretamente
      message.success('Imagem carregada e convertida com sucesso!');
    },
  });

  const handleImageCompression = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      fileType: 'image/webp',
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Erro ao comprimir a imagem:', error);
      message.error('Erro ao comprimir a imagem!');
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

  const loadDados = () => {

    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance.get(
      `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/`+idLoja,
      options
    ).then((resp) => {
      const data = resp.data.ThirdSectionContent;
      setThirdSectionTitle(data.ThirdSectionTitle);
      setThirdSectionText(data.ThirdSectionText);
      setThirdSectionYoutubeLink(data.ThirdSectionYoutubeLink);
      setThirdSectionHasImageOrVideo(data.ThirdSectionHasImageOrVideo);
      setThirdSectionImageSrc(data.ThirdSectionImageSrc);
      setThirdSectionButtonHref(data.ThirdSectionButtonHref);
      setThirdSectionButtonTxt(data.ThirdSectionButtonTxt)

      console.log(data);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error.response.status === 404) {
        console.error("Data not found");
      }
    });
  }

  useEffect(() => {
    setLoading(true);

    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, reload, idLoja]);


  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Conteúdo Terceira Seção
        </h1>

        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="ThirdSectionForm"
                  onFinish={handleSave}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label={
                      <>
                        Título da Terceira Seção &nbsp;<b>(Para não exibir a seção basta apagar o texto e salvar sem título)</b>
                      </>
                    }                    name="ThirdSectionTitle"
                    initialValue={thirdSectionTitle}
                    rules={[
                      { required: false, message: "Por favor, insira o título!" },
                    ]}
                  >
                    <Input
                      value={thirdSectionTitle}
                      onChange={(e) => setThirdSectionTitle(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Texto da Segunda Seção"
                    name="ThirdSectionText"
                    initialValue={thirdSectionText}
                    rules={[
                      { required: false, message: "Por favor, insira o texto!" },
                    ]}
                  >
                    <Input.TextArea
                      value={thirdSectionText}
                      onChange={(e) => setThirdSectionText(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link do Youtube"
                    name="ThirdSectionYoutubeLink"
                    initialValue={thirdSectionYoutubeLink}
                  >
                    <Input
                      value={thirdSectionYoutubeLink}
                      onChange={(e) => setThirdSectionYoutubeLink(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Imagem ou Vídeo"
                    name="ThirdSectionHasImageOrVideo"
                    initialValue={thirdSectionHasImageOrVideo}
                  >
                    <Select
                      value={thirdSectionHasImageOrVideo}
                      onChange={(value) => setThirdSectionHasImageOrVideo(value)}
                      options={options}
                    />
                  </Form.Item>

                  
                  <Form.Item
                    label="Fonte da Imagem (Recomendado: 600x600)"
                    name="ThirdSectionImageSrc"
                  >
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <Button icon={<UploadOutlined />}>Carregar Imagem</Button>
                    </div>
                    {thirdSectionImageSrc && (
                      <img src={thirdSectionImageSrc} alt="Preview" style={{ width: '30%', marginTop: '10px' }} />
                    )}
                  </Form.Item>
                  

                  <Form.Item
                    label="Texto do Botão (deixe vazio se não desejar o botão)"
                    name="ThirdSectionButtonTxt"
                    initialValue={thirdSectionButtonTxt}
                  >
                    <Input
                      value={thirdSectionButtonTxt}
                      onChange={(e) => setThirdSectionButtonTxt(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Link do Botão"
                    name="ThirdSectionButtonHref"
                    initialValue={thirdSectionButtonHref}
                  >
                    <Input
                      value={thirdSectionButtonHref}
                      onChange={(e) => setThirdSectionButtonHref(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SecondSessionContent;
