import React from "react";
import { Card, Button } from "antd";
import { styled } from "styled-components";
import "../styles.css";

const Sobre = () => {
  const handleContactClick = () => {
    // Coloque aqui o link para "Fale Conosco"
    window.open("https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271&text=Olá", "_blank");
  };

  const handleWebsiteClick = () => {
    window.open("https://pedtech.com.br/", "_blank");
  };

  return (
    <Container>
      <Card style={{ width: "85%", textAlign: "center" }}>
        <p><strong style={{fontSize:'24px'}}>Pedtech Manager</strong></p>
        <p><strong>Versão 1.0.8</strong></p>
        <Button type="primary" onClick={handleContactClick}
        style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                  }}  >
          Nosso Whatsapp
        </Button>
        <Button type="primary" onClick={handleWebsiteClick}
         style={{
          backgroundColor: 'black',
          borderColor: 'black',
          color: 'white'
        }}
        >
          Nosso Site
        </Button>
      </Card>
      
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82vh;
`;

export default Sobre;
