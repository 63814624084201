import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Col, Input, Button, message, Form, Skeleton, Switch } from "antd";
import axiosInstance from "../../../axiosInterceptor";

const HeaderLinksConfig = () => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [headerLinks, setHeaderLinks] = useState([{ title: "", href: "", posicao: 0, ativo: true }]);

  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [loja, setLoja] = useState(null);

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then((resp) => {
          setIDLoja(resp.data.id);
          setLoja(resp.data);
        });
    }
  }, [idUser, accessToken]);

  const handleSave = () => {
    const jsonData = headerLinks.map(link => ({
      title: link.title,
      href: link.href,   // Incluindo o href no envio de dados
      posicao: link.posicao,
      ativo: link.ativo,
    }));

    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance
        .put(
          `https://api.pedtech.com.br/v1/pedtech/headerSessaoTexto/` + idLoja,
          jsonData,
          options
        )
        .then((resp) => {
          if (resp.status === 200) {
            message.success("Links do cabeçalho salvos com sucesso!");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao chamar a API:", error);
          message.error("Erro ao salvar os links do cabeçalho!");
          setLoading(false);
        });
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      message.error("Erro ao salvar os links do cabeçalho!");
      setLoading(false);
    }
  };

  const loadDados = () => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance
      .get(`https://api.pedtech.com.br/v1/pedtech/getByEmpresa/` + idLoja, options)
      .then((resp) => {
        const headerConfig = resp.data.HeaderLinks.map(link => ({
          ...link,
          ativo: link.ativo || true, // Certifica-se de que ativo esteja setado
        }));
        setHeaderLinks(headerConfig);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, idLoja]);

  const handleInputChange = (index, field, value) => {
    const newLinks = [...headerLinks];
    newLinks[index][field] = value;
    setHeaderLinks(newLinks);
  };

  const handleToggleActive = (index, value) => {
    const newLinks = [...headerLinks];
    newLinks[index].ativo = value;
    setHeaderLinks(newLinks);
  };

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1 style={{ textAlign: "center" }}>Configuração dos Links do Cabeçalho</h1>

        {loading ? (
          <Skeleton active />
        ) : (
          <Form layout="vertical">
            {headerLinks.map((link, index) => (
              <Row key={index} gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Form.Item label={`Título do Link ${index + 1}`}>
                    <Input
                      value={link.title}
                      onChange={(e) => handleInputChange(index, "title", e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={10} style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    checked={link.ativo}
                    onChange={(checked) => handleToggleActive(index, checked)}
                  />
                  <span style={{ marginLeft: 8 }}>
                    {link.ativo ? "Exibir" : "Ocultar"}
                  </span>
                </Col>
              </Row>
            ))}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button type="primary" onClick={handleSave}>
                Salvar
              </Button>
            </div>
          </Form>
        )}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default HeaderLinksConfig;
