import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Col, Skeleton, Button, Input, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";

const FooterConfig = () => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [reload, setReload] = useState(true);

  const [footerHorario, setFooterHorario] = useState("");
  const [footerEmail, setFooterEmail] = useState("");
  const [footerAdress, setFooterAdress] = useState("");
  const [footerPhone, setFooterPhone] = useState("");
  const [footerMapsSrc, setFooterMapsSrc] = useState("");

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

  const handleSave = () => {
    // Remove iframe tags and extract src attribute if present
    const cleanMapsSrc = extractSrcFromIframe(footerMapsSrc);

    const jsonData = {
      FooterHorario: footerHorario,
      FooterEmail: footerEmail,
      FooterAdress: footerAdress,
      FooterPhone: footerPhone,
      FooterMapsSrc: cleanMapsSrc,
    };

    let body = JSON.stringify(jsonData);
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/footerSessaoTexto/` + idLoja,
        body,
        options
      ).then((resp) => {
        if (resp.status === 200) {
          message.success("Informações do rodapé salvas!");
        }
        setLoading(false);
      }).catch((error) => {
        console.error("Erro ao chamar a API:", error);
        message.error("Erro ao gravar as informações do rodapé!");
        setLoading(false);
      });

    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      message.error("Erro ao gravar as informações do rodapé!");
      setLoading(false);
    }
  };

  const loadDados = () => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axiosInstance.get(
      `https://api.pedtech.com.br/v1/pedtech/getByEmpresa/` + idLoja,
      options
    ).then((resp) => {
      const footerInfo = resp.data.FooterContent;
      setFooterHorario(footerInfo.FooterHorario);
      setFooterEmail(footerInfo.FooterEmail);
      setFooterAdress(footerInfo.FooterAdress);
      setFooterPhone(footerInfo.FooterPhone);

      // Extraindo o src do iframe se presente
      const mapsSrc = footerInfo.FooterMapsSrc;
      const src = extractSrcFromIframe(mapsSrc);
      setFooterMapsSrc(src);

      setLoading(false);
    }).catch((error) => {
      console.error("Erro ao carregar dados:", error);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, reload, idLoja]);

  // Função para extrair o src do iframe
  const extractSrcFromIframe = (htmlString) => {
    if (!htmlString) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const iframe = doc.querySelector("iframe");
    return iframe ? iframe.getAttribute("src") : htmlString; // Retorna o htmlString original se não houver iframe
  };

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1 style={{ textAlign: "center" }}>Configuração de Informações do Rodapé</h1>

        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Horário de Funcionamento:</h3>
                <Input
                  value={footerHorario}
                  onChange={(e) => setFooterHorario(e.target.value)}
                />
              </Col>
              <Col xs={24} md={12} lg={7}>
                <h3>Email de Contato:</h3>
                <Input
                  value={footerEmail}
                  onChange={(e) => setFooterEmail(e.target.value)}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={7}>
                <h3>Endereço:</h3>
                <Input
                  value={footerAdress}
                  onChange={(e) => setFooterAdress(e.target.value)}
                />
              </Col>
              <Col xs={24} md={12} lg={7}>
                <h3>Telefone:</h3>
                <Input
                  value={footerPhone}
                  onChange={(e) => setFooterPhone(e.target.value)}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Col xs={24} md={12} lg={14}>
                <h3>Embed do Google Maps:</h3>
                <Input
                  value={footerMapsSrc}
                  onChange={(e) => setFooterMapsSrc(e.target.value)}
                />
              </Col>
            </Row>

            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <Button type="primary" onClick={handleSave}>
                Salvar
              </Button>
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default FooterConfig;
