import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Row, Skeleton, Form, Input, Button, Col, message } from "antd";
import axiosInstance from "../../../axiosInterceptor";

const TagsForm = () => {
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const accessToken = window.localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  
  // Variáveis separadas para as tags
  const [googleGeneralTag, setGoogleGeneralTag] = useState("");
  const [facebookGeneralTag, setFacebookGeneralTag] = useState("");
  const [googleSection1Tag, setGoogleSection1Tag] = useState("");
  const [facebookSection1Tag, setFacebookSection1Tag] = useState("");
  const [googleSection2Tag, setGoogleSection2Tag] = useState("");
  const [facebookSection2Tag, setFacebookSection2Tag] = useState("");
  const [googleSection3Tag, setGoogleSection3Tag] = useState("");
  const [facebookSection3Tag, setFacebookSection3Tag] = useState("");


  const [Font1, setFont1] = useState(null);

  const loadDados = () => {
    if (!idLoja) return; // Evita chamada se idLoja não estiver definido

    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axiosInstance
      .get(`https://api.pedtech.com.br/v1/pedtech/getByEmpresa/${idLoja}`, options)
      .then((resp) => {
        console.log("Dados retornados:", resp.data); // Verifique a estrutura dos dados
        const sectionColors = resp.data.SocialInfos; // Adicionando fallback para evitar erros
        console.log(sectionColors.GoogleTag)
        setGoogleGeneralTag(sectionColors.GoogleTag);
        console.log(googleGeneralTag);



        setFacebookGeneralTag(sectionColors.FacebookTag);
        setGoogleSection1Tag(sectionColors.GoogleTagSection1);
        setFacebookSection1Tag(sectionColors.FacebookTagSection1);
        setGoogleSection2Tag(sectionColors.GoogleTagSection2);
        setFacebookSection2Tag(sectionColors.FacebookTagSection2);
        setGoogleSection3Tag(sectionColors.GoogleTagSection3);
        setFacebookSection3Tag(sectionColors.FacebookTagSection3);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar os dados das tags:", error);
        message.error("Erro ao carregar os dados das tags!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axiosInstance
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then((resp) => {
          setIDLoja(resp.data.id);
          setLoja(resp.data);
        })
        .catch((error) => {
          console.error("Erro ao obter loja:", error);
          message.error("Erro ao obter loja!");
        });
    }
  }, [idUser, accessToken]);

  const handleSave = () => {
    setLoading(true);
    console.log(googleGeneralTag);
    const body = JSON.stringify({
      GoogleTag: googleGeneralTag,
      FacebookTag: facebookGeneralTag,
      GoogleTagSection1: googleSection1Tag,
      FacebookTagSection1: facebookSection1Tag,
      GoogleTagSection2: googleSection2Tag,
      FacebookTagSection2: facebookSection2Tag,
      GoogleTagSection3: googleSection3Tag,
      FacebookTagSection3: facebookSection3Tag,
    });

    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axiosInstance
      .put(`https://api.pedtech.com.br/v1/pedtech/tagsForm/${idLoja}`, body, options)
      .then((resp) => {
        if (resp.status === 200) {
          message.success("Tags salvas com sucesso!");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao salvar as tags:", error);
        message.error("Erro ao salvar as tags!");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, idLoja]);

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <h1 style={{ textAlign: "center" }}>Configurações de Tags</h1>

        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="TagsForm"
                  onFinish={handleSave}
                  layout="vertical"
                  style={{ width: "100%" }}
                >

                  <h3>Tag Geral Google</h3>
                    <Input
                      value={googleGeneralTag}
                      onChange={(e) => setGoogleGeneralTag(e.target.value)}
                    />
                


                <h3>Tag Geral Facebook</h3>
                    <Input
                      value={facebookGeneralTag}
                      onChange={(e) => setFacebookGeneralTag(e.target.value)}
                    />
                  

                  <h3>Tag Seção 1 Google</h3>
                    <Input
                      value={googleSection1Tag}
                      onChange={(e) => setGoogleSection1Tag(e.target.value)}
                    />

  
                  <h3>Tag Seção 1 Facebook</h3>
                    <Input
                      value={facebookSection1Tag}
                      onChange={(e) => setFacebookSection1Tag(e.target.value)}
                    />

                  <h3>Tag Seção 2 Google</h3>
                    <Input
                      value={googleSection2Tag}
                      onChange={(e) => setGoogleSection2Tag(e.target.value)}
                    />

                  <h3>Tag Seção 2 Facebook</h3>
                    <Input
                      value={facebookSection2Tag}
                      onChange={(e) => setFacebookSection2Tag(e.target.value)}
                    />

  
                  <h3>Tag Seção 3 Google</h3>
                    <Input
                      value={googleSection3Tag}
                      onChange={(e) => setGoogleSection3Tag(e.target.value)}
                    />

                  <h3>Tag Seção 3 Facebook</h3>
                    <Input
                      value={facebookSection3Tag}
                      onChange={(e) => setFacebookSection3Tag(e.target.value)}
                    />


                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default TagsForm;
